.center1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.table2 {
    width: 790px;
    height: 150px;
   border-radius: 43px;
  -webkit-animation: blinkRed 0.7s infinite;
  -moz-animation: blinkRed 0.7s infinite;
  -ms-animation: blinkRed 0.7s infinite;
  -o-animation: blinkRed 0.7s infinite;
  animation: blinkRed 0.7s infinite;
}

@-webkit-keyframes blinkRed {
  from {
    background-color: #094a9fcc;
  }
  50% {
    background-color: #094a9fc5;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #094a9fa8 0 -1px 9px,
      #094a9fad 0 2px 0;
  }
  to {
    background-color: #094a9fb4;
  }
}
@-moz-keyframes blinkRed {
  from {
    background-color: #094a9f88;
  }
  50% {
    background-color: #094a9f8a;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #094a9f73 0 -1px 9px,
      #094a9f83 0 2px 0;
  }
  to {
    background-color: #094a9f93;
  }
}
@-ms-keyframes blinkRed {
  from {
    background-color: #094a9f8f;
  }
  50% {
    background-color: #094a9f83;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #094a9f85 0 -1px 9px,
      #094a9f91 0 2px 0;
  }
  to {
    background-color: #094a9f8a;
  }
}
@-o-keyframes blinkRed {
  from {
    background-color: #094a9f85;
  }
  50% {
    background-color: #094a9f8c;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #094a9f7e 0 -1px 9px,
      #094a9f8e 0 2px 0;
  }
  to {
    background-color: #094a9fa4;
  }
}
@keyframes blinkRed {
  from {
    background-color: #094a9fa9;
  }
  50% {
    background-color: #094a9fb7;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #094a9f8f 0 -1px 9px,
      #094a9fad 0 2px 0;
  }
  to {
    background-color: #094a9f9c;
  }
}

.table2 .monitor-wrapper {
    background: #050321;
    width: 770px;
    height: 130px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 43px;
}

.table2 .monitor-wrapper .monitor {
    width: 700px;
    height: 100px;
    background-color: #000000;
    overflow: hidden;
    white-space: nowrap;
}

.table2 .monitor-wrapper .monitor .para {
    font-family: 'Bangers', cursive;
    font-size: 100px;
    position: relative;
    display: inline-block;
    animation: move 20s infinite linear;
    background-image: linear-gradient( to right, #ed264c, #44ffa9, #ed264c, #44ffa9 );
    text-shadow: 0 4px 15px 0 #da3d5496;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes move {
    from {
        left: 800px;
    }

    to {
        left: -2000px;
    }
}


#frame {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    perspective: 800px;
    transform-style: preserve-3d;

}

#table {
    position: relative;
    width: 90%;
    height: 90%;
    perspective: 1200px;
    transform: rotateY(-15deg) rotateX(40deg);
}

#table .card {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 150px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 1px 1px 5px 1px rgba(150, 150, 150, 0.15);
}

#table .card__symbol {
    font-size: 60px;
    color: var(--card-black);
    user-select: none;
}

#table .card__symbol--red {
    color: var(--card-red);
}

#table .card--sample {
    top: 40%;
    left: 45%;
    transform: rotate(45deg);
}

.main {
    width: 700px;
    max-height: 600px;
    /* position: absolute; */
    /* margin: -100px 0 0 -200px; */
    overflow: auto;
    text-align: center;
    line-height: 129px;
    font-size: 55px;
    font-family: 'Bangers';
    color: rgb(255, 255, 255);
    text-shadow: 0 2px 5px #0000008a;
    border-radius: 46px;
    box-shadow: 0 15px 35px rgb(0 0 0 / 50%);
    background: rgb(255, 255, 255, 0.05);
    backdrop-filter: blur(8px);
    letter-spacing: 2px;
    z-index: 1;
    margin: auto;
}

@media screen and (max-width:900px) {
    .main {
        width: 100%;
        font-size: 2em;
    }
}

.button-container {
    display: flex;
    margin: 65px auto;
    flex-wrap: wrap;
    justify-content: center;
    font-family: 'Bangers', cursive;

}

.button {
    display: flex;
    overflow: hidden;
    margin: 25px;
    padding: 5px 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 150ms linear;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    text-transform: none;
    text-transform: capitalize;
    color: #fff;
    border: 0 none;
    border-radius: var(--borderRadius);
    font-size: 36px;
    font-weight: 500;
    line-height: 1.3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    justify-content: center;
    align-items: center;
    flex: 0 0 160px;
    box-shadow: 2px 5px 10px #909090;
    border-radius: 150px;
}

.button.-dark {
    color: var(--color-snow);
    background-image: linear-gradient(to right,
            #ed264c,
            #ff4468,
            #ff4064b0);
    box-shadow: 0 4px 15px 0 #da3d5496;
}

.button.-green {
    color: var(--color-snow);
    background-image: linear-gradient(to right,
            #32e5dd,
            #96ede9,
            #c0fffc);
    box-shadow: 0 4px 15px 0 #3dc1bb94;
}

.button.-blue {
    color: var(--color-snow);
    background-image: linear-gradient(to right,
            #25aae1,
            #04befe,
            #3f86ed);
    box-shadow: 0 4px 15px 0 #4184eabf;
}

.button.-sun {
    color: #ffffff;
    background-image: linear-gradient(to right,
            #6253e1,
            #8986ff,
            #a499ff);
    box-shadow: 0 4px 15px 0 #6253e1a8;
}

.button.-alge {
    color: #ffffff;
    background-image: linear-gradient(to right,
            #fe8ba1,
            #fa7199,
            #ff739c);
    box-shadow: 0 4px 15px 0 rgba(238, 112, 154);
}

.button.-flower {
    color: #ffffff;
    background: linear-gradient(to right,
            #59d79fde,
            #5bffb0,
            #ddffef);
    box-shadow: 0 4px 15px 0 rgba(91, 200, 190, 0.75);
}

.button:hover,
.button:focus {
    transform: translateY(-0.45em);
    /* moz-transition: all .4s ease-in-out; */
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    /* background-image: url("http://billhwangdefi.co/casino/12/1.png"); */
    background-position: center;
    background-size: cover;
    box-shadow: 0 15px 35px rgb(0 0 0 / 33%);
}

.wrapper {
    margin-top: 7%;
    display: flex;
    justify-content: center;
    height: 12vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

}

.wrapper:hover>.card {
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    transform: none;
}

.wrapper:hover :nth-child(1),
.wrapper:hover :nth-child(3) {
    margin-right: 0;
}

.wrapper:hover :nth-child(3),
.wrapper:hover :nth-child(5) {
    margin-left: 0;
}

.red {
    color: #e8282d;
}

.card {
    align-items: center;
    border-bottom-width: 3px;
    border-radius: 10px;
    display: flex;
    flex: 0 0 auto;
    font-size: 4em;
    justify-content: center;
    position: relative;
    height: 140px;
    transition: margin 250ms, transform 300ms;
    width: 105px;
    box-shadow: 0 0 10px #ffffff,
        0 0 10px #ffffff,
        0 0 10px #ffffff,
        0 0 10px #ffffff,
        0 0 10px #f9c947;
}

.card::after,
.card::before {
    content: '♥';
    font-family: 'Bangers', cursive;
    font-size: 0.2em;
    position: absolute;
    color: rgb(255, 0, 0);
    text-shadow: 0 0 10px #ff0000,
        0 0 20px #ff0000,
        0 0 40px #ff0000,
        0 0 80px #ff0000,
        0 0 120px #ff0000;
}

.card::after {
    left: 8px;
    top: 8px;
    content: 'B';
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff,
        0 0 20px #ffffff,
        0 0 40px #ffffff,
        0 0 80px #ffffff,
        0 0 120px #ffffff;
}

.card::before {
    bottom: 8px;
    right: 8px;
    transform: rotate(180deg);
}

.card:nth-child(1) {
    margin-right: -35px;
    transform: rotate(-15deg);
    background-image: url(card3.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (max-width:900px) {
    .card:nth-child(1) {
        display: none;
    }

    .card:nth-child(5) {
        display: none;
    }
}

.card:nth-child(2) {
    transform: rotate(-7deg);
    background-image: url(card2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card:nth-child(4) {
    transform: rotate(7deg);
    background-image: url(card1.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card:nth-child(5) {
    transform: rotate(15deg);
    background-image: url(card5.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card:nth-child(3),
.card:nth-child(5) {
    margin-left: -35px;
}

.card:nth-child(1),
.card:nth-child(5) {
    z-index: 1;
}

.card:nth-child(2),
.card:nth-child(4) {
    margin-bottom: 50px;
    z-index: 2;
}

.card:nth-child(3) {
    margin-right: -35px;
    margin-bottom: 70px;
    z-index: 3;
    background-image: url(card4.gif);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    content: 'B';
    color: gainsboro;
}

.card:nth-child(3)::after,
.card:nth-child(3)::before {
    display: none;
    content: 'B';
    color: gainsboro;
}

.loading-text {
    line-height: 127px;
    letter-spacing: 7px;
    font-size: 85px;
}

.loading-text span {
    display: inline-block;
    color: #fff;
    font-family: 'Bangers';
}

.loading-text span:nth-child(1) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
    animation: blur-text 1.5s 0s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(2) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
    animation: blur-text 1.5s 0.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(3) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
    animation: blur-text 1.5s 0.4s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(4) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
    animation: blur-text 1.5s 0.6s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;

}

.loading-text span:nth-child(5) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
    animation: blur-text 1.5s 0.8s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;
}

.loading-text span:nth-child(6) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
    animation: blur-text 1.5s 1s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;
}

.loading-text span:nth-child(7) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;
}

.loading-text span:nth-child(8) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;
}

.loading-text span:nth-child(9) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;
}

.loading-text span:nth-child(10) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #66dea2, 0 0 20px #6ae6a5, 0 0 40px #6ae6a5, 0 0 60px #6ae6a5, 0 0 115px #6ae6a5;

}

.loading-text span:nth-child(11) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(12) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(13) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(14) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

.loading-text span:nth-child(15) {
    filter: blur(0px);
    -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
    animation: blur-text 1.5s 1.2s infinite linear alternate;
    text-shadow: 0 0 10px #e13c5b, 0 0 20px #e73f5d, 0 0 40px #e63e5c, 0 0 60px #ea3f5d, 0 0 115px #e73e5d;
}

@-webkit-keyframes blur-text {
    0% {
        filter: blur(0px);
    }

    100% {
        filter: blur(4px);
    }
}

@keyframes blur-text {
    0% {
        filter: blur(0px);
    }

    100% {
        filter: blur(4px);

    }
}

/* 
.container {
    width: 274px;
    height: 402px;
    position: relative;
    -ms-perspective: 800px;
    perspective: 800px;
    border-radius: 4px;
    left: 57px;
    top: 15px;
} */

.card3 {
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 6px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.card3 div {

    box-shadow: 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #f9c947;
    -webkit-font-smoothing: antialiased;
    background: rgb(255, 255, 255, 0.05);
    border-radius: 21px;
}

.card3 .back {
    /* transform: rotateY(180deg); */
    /* background:  url("http://billhwangdefi.co/casino/12/1.png"); */
}

.flipped {
    /* background: url(king.jpeg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
}

/* .card3 .front p {

    text-align: center;
    padding: 2.60em 0 0.325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* Clip Background Image */
/* background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y; */
/* -webkit-background-clip: text; */
/* background-clip: text; */
/* Animate Background Image */
/* -webkit-text-fill-color: transparent; */
/* -webkit-animation: aitf 80s linear infinite; */
/* Activate hardware acceleration for smoother animations */
/* -webkit-transform: translate3d(0, 0, 0); */
/* font-family: Bangers !important; */
/* } */

/* Animate Background Image */
/* @-webkit-keyframes aitf { */
/* 0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    } */
/* } */

.card3 .front {
    background: url(card4.gif);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.filppedCard {
    height: 430px;
    width: 100%;
    /* background-image: url(card4.gif); */
}


.card3 .back {
    /* background:  url("http://billhwangdefi.co/casino/12/1.png"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* .container2 {
    width: 274px;
    height: 402px;
    position: relative;
    -ms-perspective: 800px;
    perspective: 800px;
    border-radius: 4px;
    left: 376px;
    top: -388px;
} */

.card4 {
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 6px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.card4 div {
    /* -webkit-backface-visibility: hidden; */
    box-shadow: 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 10px #f9c947;
    -webkit-font-smoothing: antialiased;
    background: rgb(255, 255, 255, 0.05);
    border-radius: 21px;
}

.card4 .back {
    /* transform: rotateY(180deg); */
    /* background:  url("http://billhwangdefi.co/casino/12/1.png"); */
}

.flipped {
    transform: rotateY(348deg);
    /* background: url(king.jpeg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


}



.card4 .front h1 {
    font-size: 58px;
    text-align: center;
    color: #ffffff;

}

.paraGr,
.paraGr:hover,
#pi {
    font-size: 58px;
    text-align: center;
    padding: 2.60em 0 0.325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* Clip Background Image */
    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
    -webkit-background-clip: text;
    background-clip: text;
    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    -webkit-animation-name: aitf;
    animation-name: aitf;
    -webkit-animation-duration: 50s;
    animation-duration: 50s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    /* Activate hardware acceleration for smoother animations */
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    font-family: 'Bangers' !important;
}

@-webkit-keyframes aitf {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.card4 .front {
    background: url(card4.gif);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card4 .back {
    /* background:  url("http://billhwangdefi.co/casino/12/1.png"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

/*
   * Animation module with all animation code
   */
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300);

.anim-text-flow,
.anim-text-flow-hover:hover {
    /*
     * Animation variables
     */
    /*
     * Elements settings
     */
    /*
     * Keyframe loop
     */
    /*
     * Element animation delay loop
     */
}

.anim-text-flow,
.anim-text-flow-hover:hover {
    -webkit-animation-name: anim-text-flow-keys;
    animation-name: anim-text-flow-keys;
    -webkit-animation-duration: 50s;
    animation-duration: 50s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@-webkit-keyframes anim-text-flow-keys {
    0% {
        color: #685cd6;
    }

    5% {
        color: #cad65c;
    }

    10% {
        color: #6a5cd6;
    }

    15% {
        color: #5cd6af;
    }

    20% {
        color: #d65cb8;
    }

    25% {
        color: #685cd6;
    }

    30% {
        color: #d6605c;
    }

    35% {
        color: #d65c72;
    }

    40% {
        color: #5cd689;
    }

    45% {
        color: #d65c7a;
    }

    50% {
        color: #d6a75c;
    }

    55% {
        color: #5cd693;
    }

    60% {
        color: #d6665c;
    }

    65% {
        color: #8dd65c;
    }

    70% {
        color: #cad65c;
    }

    75% {
        color: #5ca1d6;
    }

    80% {
        color: #b6d65c;
    }

    85% {
        color: #5cd685;
    }

    90% {
        color: #ced65c;
    }

    95% {
        color: #000000;
    }

    100% {
        color: #d6d45c;
    }
}

@keyframes anim-text-flow-keys {
    0% {
        color: #ffffff;
    }

    5% {
        color: #cad65c;
    }

    10% {
        color: #6a5cd6;
    }

    15% {
        color: #5cd6af;
    }

    20% {
        color: #f5851d;
    }

    25% {
        color: #685cd6;
    }

    30% {
        color: #d6605c;
    }

    35% {
        color: #d65c72;
    }

    40% {
        color: #5cd689;
    }

    45% {
        color: #d65c7a;
    }

    50% {
        color: #d6a75c;
    }

    55% {
        color: #5cd693;
    }

    60% {
        color: #d6665c;
    }

    65% {
        color: #8dd65c;
    }

    70% {
        color: #cad65c;
    }

    75% {
        color: #5ca1d6;
    }

    80% {
        color: #b6d65c;
    }

    85% {
        color: #5cd685;
    }

    90% {
        color: #ffffff;
    }

    95% {
        color: #91e741;
    }

    100% {
        color: #faa21d;
    }
}

.anim-text-flow span:nth-of-type(1),
.anim-text-flow-hover:hover span:nth-of-type(1) {
    -webkit-animation-delay: -19.8s;
    animation-delay: -19.8s;
}

.anim-text-flow span:nth-of-type(2),
.anim-text-flow-hover:hover span:nth-of-type(2) {
    -webkit-animation-delay: -19.6s;
    animation-delay: -19.6s;
}

.anim-text-flow span:nth-of-type(3),
.anim-text-flow-hover:hover span:nth-of-type(3) {
    -webkit-animation-delay: -19.4s;
    animation-delay: -19.4s;
}

.anim-text-flow span:nth-of-type(4),
.anim-text-flow-hover:hover span:nth-of-type(4) {
    -webkit-animation-delay: -19.2s;
    animation-delay: -19.2s;
}

.anim-text-flow span:nth-of-type(5),
.anim-text-flow-hover:hover span:nth-of-type(5) {
    -webkit-animation-delay: -19s;
    animation-delay: -19s;
}

.anim-text-flow span:nth-of-type(6),
.anim-text-flow-hover:hover span:nth-of-type(6) {
    -webkit-animation-delay: -18.8s;
    animation-delay: -18.8s;
}

.anim-text-flow span:nth-of-type(7),
.anim-text-flow-hover:hover span:nth-of-type(7) {
    -webkit-animation-delay: -18.6s;
    animation-delay: -18.6s;
}

.anim-text-flow span:nth-of-type(8),
.anim-text-flow-hover:hover span:nth-of-type(8) {
    -webkit-animation-delay: -18.4s;
    animation-delay: -18.4s;
}

.anim-text-flow span:nth-of-type(9),
.anim-text-flow-hover:hover span:nth-of-type(9) {
    -webkit-animation-delay: -18.2s;
    animation-delay: -18.2s;
}

.anim-text-flow span:nth-of-type(10),
.anim-text-flow-hover:hover span:nth-of-type(10) {
    -webkit-animation-delay: -18s;
    animation-delay: -18s;
}

.anim-text-flow span:nth-of-type(11),
.anim-text-flow-hover:hover span:nth-of-type(11) {
    -webkit-animation-delay: -17.8s;
    animation-delay: -17.8s;
}

.anim-text-flow span:nth-of-type(12),
.anim-text-flow-hover:hover span:nth-of-type(12) {
    -webkit-animation-delay: -17.6s;
    animation-delay: -17.6s;
}

.anim-text-flow span:nth-of-type(13),
.anim-text-flow-hover:hover span:nth-of-type(13) {
    -webkit-animation-delay: -17.4s;
    animation-delay: -17.4s;
}

.anim-text-flow span:nth-of-type(14),
.anim-text-flow-hover:hover span:nth-of-type(14) {
    -webkit-animation-delay: -17.2s;
    animation-delay: -17.2s;
}

.anim-text-flow span:nth-of-type(15),
.anim-text-flow-hover:hover span:nth-of-type(15) {
    -webkit-animation-delay: -17s;
    animation-delay: -17s;
}

.anim-text-flow span:nth-of-type(16),
.anim-text-flow-hover:hover span:nth-of-type(16) {
    -webkit-animation-delay: -16.8s;
    animation-delay: -16.8s;
}

.anim-text-flow span:nth-of-type(17),
.anim-text-flow-hover:hover span:nth-of-type(17) {
    -webkit-animation-delay: -16.6s;
    animation-delay: -16.6s;
}

.anim-text-flow span:nth-of-type(18),
.anim-text-flow-hover:hover span:nth-of-type(18) {
    -webkit-animation-delay: -16.4s;
    animation-delay: -16.4s;
}

.anim-text-flow span:nth-of-type(19),
.anim-text-flow-hover:hover span:nth-of-type(19) {
    -webkit-animation-delay: -16.2s;
    animation-delay: -16.2s;
}

.anim-text-flow span:nth-of-type(20),
.anim-text-flow-hover:hover span:nth-of-type(20) {
    -webkit-animation-delay: -16s;
    animation-delay: -16s;
}

.anim-text-flow span:nth-of-type(21),
.anim-text-flow-hover:hover span:nth-of-type(21) {
    -webkit-animation-delay: -15.8s;
    animation-delay: -15.8s;
}

.anim-text-flow span:nth-of-type(22),
.anim-text-flow-hover:hover span:nth-of-type(22) {
    -webkit-animation-delay: -15.6s;
    animation-delay: -15.6s;
}

.anim-text-flow span:nth-of-type(23),
.anim-text-flow-hover:hover span:nth-of-type(23) {
    -webkit-animation-delay: -15.4s;
    animation-delay: -15.4s;
}

.anim-text-flow span:nth-of-type(24),
.anim-text-flow-hover:hover span:nth-of-type(24) {
    -webkit-animation-delay: -15.2s;
    animation-delay: -15.2s;
}

.anim-text-flow span:nth-of-type(25),
.anim-text-flow-hover:hover span:nth-of-type(25) {
    -webkit-animation-delay: -15s;
    animation-delay: -15s;
}

.anim-text-flow span:nth-of-type(26),
.anim-text-flow-hover:hover span:nth-of-type(26) {
    -webkit-animation-delay: -14.8s;
    animation-delay: -14.8s;
}

.anim-text-flow span:nth-of-type(27),
.anim-text-flow-hover:hover span:nth-of-type(27) {
    -webkit-animation-delay: -14.6s;
    animation-delay: -14.6s;
}

.anim-text-flow span:nth-of-type(28),
.anim-text-flow-hover:hover span:nth-of-type(28) {
    -webkit-animation-delay: -14.4s;
    animation-delay: -14.4s;
}

.anim-text-flow span:nth-of-type(29),
.anim-text-flow-hover:hover span:nth-of-type(29) {
    -webkit-animation-delay: -14.2s;
    animation-delay: -14.2s;
}

.anim-text-flow span:nth-of-type(30),
.anim-text-flow-hover:hover span:nth-of-type(30) {
    -webkit-animation-delay: -14s;
    animation-delay: -14s;
}

.anim-text-flow span:nth-of-type(31),
.anim-text-flow-hover:hover span:nth-of-type(31) {
    -webkit-animation-delay: -13.8s;
    animation-delay: -13.8s;
}

.anim-text-flow span:nth-of-type(32),
.anim-text-flow-hover:hover span:nth-of-type(32) {
    -webkit-animation-delay: -13.6s;
    animation-delay: -13.6s;
}

.anim-text-flow span:nth-of-type(33),
.anim-text-flow-hover:hover span:nth-of-type(33) {
    -webkit-animation-delay: -13.4s;
    animation-delay: -13.4s;
}

.anim-text-flow span:nth-of-type(34),
.anim-text-flow-hover:hover span:nth-of-type(34) {
    -webkit-animation-delay: -13.2s;
    animation-delay: -13.2s;
}

.anim-text-flow span:nth-of-type(35),
.anim-text-flow-hover:hover span:nth-of-type(35) {
    -webkit-animation-delay: -13s;
    animation-delay: -13s;
}

.anim-text-flow span:nth-of-type(36),
.anim-text-flow-hover:hover span:nth-of-type(36) {
    -webkit-animation-delay: -12.8s;
    animation-delay: -12.8s;
}

.anim-text-flow span:nth-of-type(37),
.anim-text-flow-hover:hover span:nth-of-type(37) {
    -webkit-animation-delay: -12.6s;
    animation-delay: -12.6s;
}

.anim-text-flow span:nth-of-type(38),
.anim-text-flow-hover:hover span:nth-of-type(38) {
    -webkit-animation-delay: -12.4s;
    animation-delay: -12.4s;
}

.anim-text-flow span:nth-of-type(39),
.anim-text-flow-hover:hover span:nth-of-type(39) {
    -webkit-animation-delay: -12.2s;
    animation-delay: -12.2s;
}

.anim-text-flow span:nth-of-type(40),
.anim-text-flow-hover:hover span:nth-of-type(40) {
    -webkit-animation-delay: -12s;
    animation-delay: -12s;
}

.anim-text-flow span:nth-of-type(41),
.anim-text-flow-hover:hover span:nth-of-type(41) {
    -webkit-animation-delay: -11.8s;
    animation-delay: -11.8s;
}

.anim-text-flow span:nth-of-type(42),
.anim-text-flow-hover:hover span:nth-of-type(42) {
    -webkit-animation-delay: -11.6s;
    animation-delay: -11.6s;
}

.anim-text-flow span:nth-of-type(43),
.anim-text-flow-hover:hover span:nth-of-type(43) {
    -webkit-animation-delay: -11.4s;
    animation-delay: -11.4s;
}

.anim-text-flow span:nth-of-type(44),
.anim-text-flow-hover:hover span:nth-of-type(44) {
    -webkit-animation-delay: -11.2s;
    animation-delay: -11.2s;
}

.anim-text-flow span:nth-of-type(45),
.anim-text-flow-hover:hover span:nth-of-type(45) {
    -webkit-animation-delay: -11s;
    animation-delay: -11s;
}

.anim-text-flow span:nth-of-type(46),
.anim-text-flow-hover:hover span:nth-of-type(46) {
    -webkit-animation-delay: -10.8s;
    animation-delay: -10.8s;
}

.anim-text-flow span:nth-of-type(47),
.anim-text-flow-hover:hover span:nth-of-type(47) {
    -webkit-animation-delay: -10.6s;
    animation-delay: -10.6s;
}

.anim-text-flow span:nth-of-type(48),
.anim-text-flow-hover:hover span:nth-of-type(48) {
    -webkit-animation-delay: -10.4s;
    animation-delay: -10.4s;
}

.anim-text-flow span:nth-of-type(49),
.anim-text-flow-hover:hover span:nth-of-type(49) {
    -webkit-animation-delay: -10.2s;
    animation-delay: -10.2s;
}

.anim-text-flow span:nth-of-type(50),
.anim-text-flow-hover:hover span:nth-of-type(50) {
    -webkit-animation-delay: -10s;
    animation-delay: -10s;
}

.anim-text-flow span:nth-of-type(51),
.anim-text-flow-hover:hover span:nth-of-type(51) {
    -webkit-animation-delay: -9.8s;
    animation-delay: -9.8s;
}

.anim-text-flow span:nth-of-type(52),
.anim-text-flow-hover:hover span:nth-of-type(52) {
    -webkit-animation-delay: -9.6s;
    animation-delay: -9.6s;
}

.anim-text-flow span:nth-of-type(53),
.anim-text-flow-hover:hover span:nth-of-type(53) {
    -webkit-animation-delay: -9.4s;
    animation-delay: -9.4s;
}

.anim-text-flow span:nth-of-type(54),
.anim-text-flow-hover:hover span:nth-of-type(54) {
    -webkit-animation-delay: -9.2s;
    animation-delay: -9.2s;
}

.anim-text-flow span:nth-of-type(55),
.anim-text-flow-hover:hover span:nth-of-type(55) {
    -webkit-animation-delay: -9s;
    animation-delay: -9s;
}

.anim-text-flow span:nth-of-type(56),
.anim-text-flow-hover:hover span:nth-of-type(56) {
    -webkit-animation-delay: -8.8s;
    animation-delay: -8.8s;
}

.anim-text-flow span:nth-of-type(57),
.anim-text-flow-hover:hover span:nth-of-type(57) {
    -webkit-animation-delay: -8.6s;
    animation-delay: -8.6s;
}

.anim-text-flow span:nth-of-type(58),
.anim-text-flow-hover:hover span:nth-of-type(58) {
    -webkit-animation-delay: -8.4s;
    animation-delay: -8.4s;
}

.anim-text-flow span:nth-of-type(59),
.anim-text-flow-hover:hover span:nth-of-type(59) {
    -webkit-animation-delay: -8.2s;
    animation-delay: -8.2s;
}

.anim-text-flow span:nth-of-type(60),
.anim-text-flow-hover:hover span:nth-of-type(60) {
    -webkit-animation-delay: -8s;
    animation-delay: -8s;
}

.anim-text-flow span:nth-of-type(61),
.anim-text-flow-hover:hover span:nth-of-type(61) {
    -webkit-animation-delay: -7.8s;
    animation-delay: -7.8s;
}

.anim-text-flow span:nth-of-type(62),
.anim-text-flow-hover:hover span:nth-of-type(62) {
    -webkit-animation-delay: -7.6s;
    animation-delay: -7.6s;
}

.anim-text-flow span:nth-of-type(63),
.anim-text-flow-hover:hover span:nth-of-type(63) {
    -webkit-animation-delay: -7.4s;
    animation-delay: -7.4s;
}

.anim-text-flow span:nth-of-type(64),
.anim-text-flow-hover:hover span:nth-of-type(64) {
    -webkit-animation-delay: -7.2s;
    animation-delay: -7.2s;
}

.anim-text-flow span:nth-of-type(65),
.anim-text-flow-hover:hover span:nth-of-type(65) {
    -webkit-animation-delay: -7s;
    animation-delay: -7s;
}

.anim-text-flow span:nth-of-type(66),
.anim-text-flow-hover:hover span:nth-of-type(66) {
    -webkit-animation-delay: -6.8s;
    animation-delay: -6.8s;
}

.anim-text-flow span:nth-of-type(67),
.anim-text-flow-hover:hover span:nth-of-type(67) {
    -webkit-animation-delay: -6.6s;
    animation-delay: -6.6s;
}

.anim-text-flow span:nth-of-type(68),
.anim-text-flow-hover:hover span:nth-of-type(68) {
    -webkit-animation-delay: -6.4s;
    animation-delay: -6.4s;
}

.anim-text-flow span:nth-of-type(69),
.anim-text-flow-hover:hover span:nth-of-type(69) {
    -webkit-animation-delay: -6.2s;
    animation-delay: -6.2s;
}

.anim-text-flow span:nth-of-type(70),
.anim-text-flow-hover:hover span:nth-of-type(70) {
    -webkit-animation-delay: -6s;
    animation-delay: -6s;
}

.anim-text-flow span:nth-of-type(71),
.anim-text-flow-hover:hover span:nth-of-type(71) {
    -webkit-animation-delay: -5.8s;
    animation-delay: -5.8s;
}

.anim-text-flow span:nth-of-type(72),
.anim-text-flow-hover:hover span:nth-of-type(72) {
    -webkit-animation-delay: -5.6s;
    animation-delay: -5.6s;
}

.anim-text-flow span:nth-of-type(73),
.anim-text-flow-hover:hover span:nth-of-type(73) {
    -webkit-animation-delay: -5.4s;
    animation-delay: -5.4s;
}

.anim-text-flow span:nth-of-type(74),
.anim-text-flow-hover:hover span:nth-of-type(74) {
    -webkit-animation-delay: -5.2s;
    animation-delay: -5.2s;
}

.anim-text-flow span:nth-of-type(75),
.anim-text-flow-hover:hover span:nth-of-type(75) {
    -webkit-animation-delay: -5s;
    animation-delay: -5s;
}

.anim-text-flow span:nth-of-type(76),
.anim-text-flow-hover:hover span:nth-of-type(76) {
    -webkit-animation-delay: -4.8s;
    animation-delay: -4.8s;
}

.anim-text-flow span:nth-of-type(77),
.anim-text-flow-hover:hover span:nth-of-type(77) {
    -webkit-animation-delay: -4.6s;
    animation-delay: -4.6s;
}

.anim-text-flow span:nth-of-type(78),
.anim-text-flow-hover:hover span:nth-of-type(78) {
    -webkit-animation-delay: -4.4s;
    animation-delay: -4.4s;
}

.anim-text-flow span:nth-of-type(79),
.anim-text-flow-hover:hover span:nth-of-type(79) {
    -webkit-animation-delay: -4.2s;
    animation-delay: -4.2s;
}

.anim-text-flow span:nth-of-type(80),
.anim-text-flow-hover:hover span:nth-of-type(80) {
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
}

.anim-text-flow span:nth-of-type(81),
.anim-text-flow-hover:hover span:nth-of-type(81) {
    -webkit-animation-delay: -3.8s;
    animation-delay: -3.8s;
}

.anim-text-flow span:nth-of-type(82),
.anim-text-flow-hover:hover span:nth-of-type(82) {
    -webkit-animation-delay: -3.6s;
    animation-delay: -3.6s;
}

.anim-text-flow span:nth-of-type(83),
.anim-text-flow-hover:hover span:nth-of-type(83) {
    -webkit-animation-delay: -3.4s;
    animation-delay: -3.4s;
}

.anim-text-flow span:nth-of-type(84),
.anim-text-flow-hover:hover span:nth-of-type(84) {
    -webkit-animation-delay: -3.2s;
    animation-delay: -3.2s;
}

.anim-text-flow span:nth-of-type(85),
.anim-text-flow-hover:hover span:nth-of-type(85) {
    -webkit-animation-delay: -3s;
    animation-delay: -3s;
}

.anim-text-flow span:nth-of-type(86),
.anim-text-flow-hover:hover span:nth-of-type(86) {
    -webkit-animation-delay: -2.8s;
    animation-delay: -2.8s;
}

.anim-text-flow span:nth-of-type(87),
.anim-text-flow-hover:hover span:nth-of-type(87) {
    -webkit-animation-delay: -2.6s;
    animation-delay: -2.6s;
}

.anim-text-flow span:nth-of-type(88),
.anim-text-flow-hover:hover span:nth-of-type(88) {
    -webkit-animation-delay: -2.4s;
    animation-delay: -2.4s;
}

.anim-text-flow span:nth-of-type(89),
.anim-text-flow-hover:hover span:nth-of-type(89) {
    -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
}

.anim-text-flow span:nth-of-type(90),
.anim-text-flow-hover:hover span:nth-of-type(90) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.anim-text-flow span:nth-of-type(91),
.anim-text-flow-hover:hover span:nth-of-type(91) {
    -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
}

.anim-text-flow span:nth-of-type(92),
.anim-text-flow-hover:hover span:nth-of-type(92) {
    -webkit-animation-delay: -1.6s;
    animation-delay: -1.6s;
}

.anim-text-flow span:nth-of-type(93),
.anim-text-flow-hover:hover span:nth-of-type(93) {
    -webkit-animation-delay: -1.4s;
    animation-delay: -1.4s;
}

.anim-text-flow span:nth-of-type(94),
.anim-text-flow-hover:hover span:nth-of-type(94) {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.anim-text-flow span:nth-of-type(95),
.anim-text-flow-hover:hover span:nth-of-type(95) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.anim-text-flow span:nth-of-type(96),
.anim-text-flow-hover:hover span:nth-of-type(96) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.anim-text-flow span:nth-of-type(97),
.anim-text-flow-hover:hover span:nth-of-type(97) {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.anim-text-flow span:nth-of-type(98),
.anim-text-flow-hover:hover span:nth-of-type(98) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.anim-text-flow span:nth-of-type(99),
.anim-text-flow-hover:hover span:nth-of-type(99) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.anim-text-flow span:nth-of-type(100),
.anim-text-flow-hover:hover span:nth-of-type(100) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.txt {
    display: block;
    font-family: Bangers !important;
    font-size: 70px;
}